import React from "react";
import { motion } from "framer-motion";
import { Button } from "react-bootstrap";
import { Image } from 'react-bootstrap';
import "./opening.css"
import quinbuslogo from './static/frontend/images/quinbuslogo.jpg';

function OpeningPage(){
    function setIntro(){
        sessionStorage.setItem("intro", true)
    }
    
    return(
 <body className="opening-body">
    <motion.div
            initial={{ opacity: 0}}
            animate={{ opacity: 2}}
            transition={{ duration: 6 }}
            exit={{ opacity: 0 }}
            >
        <div className="alignment container-center opening-body">
            <motion.h1  className="bebas-neue-bold-opening" transition={{ duration: 6 }}>
                Securing The Inevitable
            </motion.h1>
            <Image  src={quinbuslogo} ></Image> 
            </div>
            <div className="small-margin">
                <Button href="/" className="text-margin outfit-regular" variant="outline-light" onClick={() => setIntro()}>Learn More</Button>
                <Button href="/contacts" className="text-margin outfit-regular" variant="outline-light" onClick={() => setIntro()}>Contact Us</Button>
            </div>     
    </motion.div>
    </body>
    );
}

export default OpeningPage