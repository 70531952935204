import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Image } from 'react-bootstrap';
import facebio from '../static/frontend/images/facebio.jpg';
import '../bio.css';

function PatrickBio() {
  return (
    <Container>
    <h1 className='header bebas-neue-bold'> Meet the Founder</h1>
      <Row>
        <Col sm={3} className="container-center">
            <Image className="img-sizing" src={facebio} rounded></Image> 
        </Col>
        <Col sm={9}><p className='outfit-regular-text'>Dr. Patrick M. Bell is the founder of Qunimbus and co-creator of QS³. 
        Over the last thirty years, he has advised governments at all levels from local to international. He holds a Ph.D. in Public Affairs, from Florida International University.  
He has focused on applying data and analytics to improve the effectiveness and efficiency of programs 
for both governmental and private sector organizations at the strategic level for 10 years. 
In 2017, he moved to the United Arab Emirates to teach at the National Defense College in Abu Dhabi. He is the recipient of the Golden visa and wishes to help the UAE be at the forefront of technology. </p> </Col>
      </Row>
    </Container>
  );
}

export default PatrickBio;