import Container from 'react-bootstrap/Container';
import '../bio.css';

function Companybio() {
  return (
    <Container>
    <h1 className='header bebas-neue-bold'> About Qunimbus</h1>
   
        <div><p className='outfit-regular-text indent'>   The world is approaching an inflection point. 
        Technology is progressing in a way that is outpacing strategic decision makers’ ability to keep up. 
        Transforming the geopolitical and geoeconomics landscape in disruptive ways that are difficult to predict, 
        let alone control. Cryptocurrency crises and stock market flash crashes. There are now, deep fakes so convincing 
        that they destabilize both allies and adversaries’ news cycles.  Digital disinformation that spreads so quickly, 
        it disrupts government’s ability to communicate their narratives. The volume, velocity and variety of disruption is 
        the inevitable factor that unites these trends. 
        Not only are there greater amounts of disruption, the speed and spread of disruption is increasing.  
     
        </p> 
        <br></br>
        <p className='outfit-regular-text'>
          The time is now to move from reactively creating strategies sequentially to deal with the challenges of emerging technologies to proactively creating strategies simultaneously to take advantage of the opportunities presented by the coming wave of quantum technologies. With Qunimbus QS³, decision makers will be ahead of the curve. “Securing the inevitable.” 

        </p>
        </div>
   
    </Container>
  );
}

export default Companybio;