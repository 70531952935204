import CollapsibleExample from './Navbar2';
import ContainerExample from './company/ComapanyLogoHeader';
import PatrickBio from './client/PersonBio';
import CompanyBio from './company/CompanyMissionStatement';
import './index.css'
import ContactUs from './client/Contact';
import { Routes ,Route } from 'react-router-dom';
import OpeningPage from './OpeningPage';
// import NavDropdownExample from './Navbar';


function App() {
  let intro = window.sessionStorage.getItem("intro");

  if (!intro || intro === "undefined" || intro === "false") {
    return (
      <body className='opening'>
      <div>
      <OpeningPage/>
      <Routes>
        <Route path='/' element={<CompanyBio/>}/>
          <Route path='/about' element={<PatrickBio/>}/>
          <Route path="/contacts" element={<ContactUs />}/> 
      </Routes> 
      </div>
      </body>
  );
} else {
  return (
    <div className='normal'>
      <CollapsibleExample/>
      <ContainerExample/>
      <Routes>
        <Route path='/' element={<CompanyBio/>}/>
          <Route path='/about' element={<PatrickBio/>}/>
          <Route path="/contacts" element={<ContactUs />}/> 
      </Routes> 
    </div>  
    );
}
}
export default App;
