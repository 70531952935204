import React, { useRef } from 'react';
import Form from 'react-bootstrap/Form';
import emailjs from '@emailjs/browser';
import '../contacts.css'


export const ContactUs = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', form.current, {
        publicKey: 'YOUR_PUBLIC_KEY',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  return (
    <>
    <h1 className="bebas-neue-bold-header">Contact Form</h1>
    <h2 className='outfit-regular-header'>Get in contact with us and get more information</h2>
    <form ref={form} onSubmit={sendEmail}>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label className='header bebas-neue-bold-label'>Name</Form.Label>
        <Form.Control type="text" placeholder="Name" />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label className='header bebas-neue-bold-label'>Email address</Form.Label>
        <Form.Control type="email" placeholder="name@example.com" name="user_email"/>
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label className='header bebas-neue-bold-label'> Leave Your Message Here</Form.Label>
        <Form.Control as="textarea" rows={8} name="message" />
      </Form.Group>
      <input type="submit" value="Send" />
    </form>
    </>
  );
};

export default ContactUs;