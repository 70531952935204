
import Image from 'react-bootstrap/Image';
import quinbuslogo from '../static/frontend/images/quinbuslogo.jpg'
import  '../logo.css'

function ContainerExample() {
  return (
    <div className='container-size'>
       <Image className='img-size' src={quinbuslogo} fluid/>
    </div>
  );
}

export default ContainerExample;